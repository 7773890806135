const { isAuthenticated } = require("../services/authentication");

const auth = (props) => {
	return new Promise((resolve, reject) => {
		const authenticated = isAuthenticated();
		authenticated
			? authenticated.payload?.institucional
				? reject(new Error("/institutional"))
				: resolve(authenticated.payload)
			: reject(new Error("/login"));
	});
};

const noAuth = (props) => {
	return new Promise((resolve, reject) => {
		!isAuthenticated() ? resolve("") : reject(new Error("/dashboard/orders"));
	});
};

const noAuthOrValidRole = (props) => {
	return new Promise((resolve, reject) => {
		const token = isAuthenticated();
		!token ||
		token?.payload?.superusuario ||
		token?.payload?.cajero ||
		token?.payload?.gestion ||
		token?.payload?.callcenter
			? resolve(token?.payload || "")
			: token?.payload?.institucional
			? reject(new Error("/institutional"))
			: reject(new Error("/dashboard/orders"));
	});
};

const institutionalRole = (props) => {
	return new Promise((resolve, reject) => {
		const authenticated = isAuthenticated();
		authenticated
			? authenticated.payload?.institucional ||
			  authenticated.payload?.asesor ||
			  authenticated.payload?.superusuario
				? resolve(authenticated.payload)
				: reject(new Error(""))
			: reject(new Error("/login"));
	});
};

const creationRole = (props) => {
	return new Promise((resolve, reject) => {
		const authenticated = isAuthenticated();
		authenticated
			? authenticated.payload?.superusuario ||
			  authenticated.payload?.gestion ||
			  authenticated.payload?.asesor ||
			  authenticated.payload?.cajero ||
			  authenticated.payload?.callcenter
				? resolve(authenticated.payload)
				: reject(new Error("/"))
			: reject(new Error("/"));
	});
};

const allAuths = (props) => {
	return new Promise((resolve, reject) => {
		const token = isAuthenticated();
		resolve(token?.payload || "");
	});
};

module.exports = {
	auth,
	noAuth,
	creationRole,
	noAuthOrValidRole,
	allAuths,
	institutionalRole,
};
